<template>
  <div id="cart-checkout-header">
    <div class="bg-big-text">
      <BigText
        color="white"
        bgcolor="red"
        :title="$t('cart.big1')"
        position="left"
      />
      <div class="text-mes-achats">
        <BigText
          color="white"
          bgcolor="red"
          :title="$t('cart.big2')"
          position="left"
        />
      </div>
    </div>
    <div class="container section-title">
      <div
        class="row section-1"
        :class="{ active: section1 }"
      >
        <img src="@/assets/section-number-commande.svg">
        <img src="@/assets/section-number-delivery-outline.svg">
        <img src="@/assets/section-number-checkout-outline.svg">
      </div>
      <div
        class="row section-2"
        :class="{ active: section2 }"
      >
        <img src="@/assets/section-number-commande-outline.svg">
        <img src="@/assets/section-number-delivery.svg">
        <img src="@/assets/section-number-checkout-outline.svg">
      </div>
      <div
        class="row section-3"
        :class="{ active: section3 }"
      >
        <img src="@/assets/section-number-commande-outline.svg">
        <img src="@/assets/section-number-delivery-outline.svg">
        <img src="@/assets/section-number-checkout.svg">
      </div>
      <div class="row">
        <h2>{{ title }}</h2>
      </div>
    </div>
    <img
      src="@/assets/cart-big-icon.svg"
      class="big-icon"
      :class="{ active: section1 }"
    >
    <img
      src="@/assets/livraison-big-icon.svg"
      class="big-icon"
      :class="{ active: section2 }"
    >
    <img
      src="@/assets/paiement-big-icon.svg"
      class="big-icon"
      :class="{ active: section3 }"
    >
  </div>
</template>

<script>
import BigText from '@/components/UI/BigText.vue'

export default {
  name: 'CartCheckoutHeader',
  components: {
    BigText
  },
  props: {
    title: {
      type: String
    },
    section1: {
      type: Boolean,
      default: false
    },
    section2: {
      type: Boolean,
      default: false
    },
    section3: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#cart-checkout-header {
    background-color: var(--red-color);
    height: 380px;
    position: relative;
    display: flex;
    align-items: flex-end;
    padding-bottom: 105px;
    @include media-breakpoint-up(md) {
        align-items: center;
        padding-bottom: 0;
        height: 390px;
    }

    .bg-big-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0.6;
        @include media-breakpoint-up(md) {
            margin-top: 50px;
        }
    }
    h2 {
        font-size: 60px !important;
        text-transform: uppercase;
        line-height: 50px;

        @include media-breakpoint-up(md) {
            font-size: 100px !important;
        }

        @include media-breakpoint-up(lg) {
            font-size: 150px !important;
            line-height: 140px;
        }
    }
    .text-mes-achats h2{
        top:50px;
        @include media-breakpoint-up(md) {
            top:90px;
        }
        @include media-breakpoint-up(lg) {
            top:120px;
        }
    }
    .big-icon {
        position: absolute;
        bottom: -40px;
        right: 0;
        margin-right: 10%;
        max-width: 150px;
        max-height: 150px;

        @include media-breakpoint-up(md) {
            bottom: -70px;
            max-width: 200px;
            max-height: 200px;
        }

        @include media-breakpoint-up(lg) {
            bottom: -85px;
        }

        @include media-breakpoint-up(lg) {
            bottom: calc(50% - 100px);
        }
    }
    .section-title {
        text-align: left;
        z-index: 2;
        h2 {
            font-size: 30px !important;
            line-height: 40px !important;
            color: white;
            font-weight: 900;
            margin-top: 5px;
            @include media-breakpoint-up(md) {
                font-size: 35px !important;
            }
            @include media-breakpoint-up(lg) {
                font-size: 40px !important;
            }
        }
        img {
            max-width: 80px;
            @include media-breakpoint-up(md) {
                max-width: 100px;
            }
            @include media-breakpoint-up(lg) {
                max-width: 110px;
            }
        }
    }
}
img.big-icon:not(.active) {
    display: none;
}

.section-1:not(.active), .section-2:not(.active), .section-3:not(.active) {
    display: none;
}
.section-1.active, .section-2.active, .section-3.active {
    display: flex;
}
</style>
