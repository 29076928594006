<template>
  <div
    id="totals"
    class="row"
  >
    <div class="col-6 offset-lg-3 col-lg-2">
      <span v-if="cart" class="red">{{ offers.length }}</span> {{ $t('payment.totalArticles') }}
    </div>
    <div class="col-6 offset-lg-0 col-lg-4 d-flex flex-direction-row justify-content-lg-end">
      {{ $t('payment.total') }}<span class="total-amount"> <span v-if="cart" class="red">{{ cart.getTotalPriceStuff() | price }}</span></span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Totals',
  props: {
    partnerId: { type: String }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    cart() {
      return this.$store.getters['cart/cart']
    },
    offers() {
      return this.$store.getters['cart/offers']()
    }
  },
  mounted() {
    this.loading = true
    return this.$store.dispatch('cart/get', { partnerId: this.partnerId })
      .catch(err => this.$err(err))
      .finally(() => (this.loading = false))
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/youpaq.scss';

#totals {
    padding: 30px 20px;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: white;
    background-color: var(--red-color);

    .total-amount {
      padding-left: 15px;
    }
}
</style>
